import React from "react";

import Layout from "../components/layout";
import privacyHtml from "raw-loader!../../static/privacy.html";

const PrivacyPage = () => (
  <Layout>
    <section
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "4rem 1rem",
        paddingBottom: 0,
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: privacyHtml }} />
      <br />
    </section>
  </Layout>
);

export default PrivacyPage;
